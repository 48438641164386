import React from "react"
import Layout from "../components/layout"
import {observer, inject} from 'mobx-react'
import { Link } from "gatsby"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { decorate, transaction } from "mobx"


class Cart extends React.Component {

  constructor(props) {
    super(props)
    let { store } = this.props
    this.itemRefs = store._items.map(item => React.createRef())
  }

  render() {
    let { store } = this.props

    return (
      <Layout title="Shopping Cart">
        <h2>Shopping Cart ({store.itemCount} items)</h2>
        {/*{JSON.stringify(store.items)}*/}
        <br/>
        {store.itemCount ?
          <div>
            <table>
              <thead>
              <tr>
                <th>Item Name</th>
                <th style={{ width: '100px' }}>Price</th>
                <th style={{ width: '30px' }}>Count</th>
                <th>Line Total</th>
              </tr>
              </thead>
              <tbody>
              {store.items.map((item, i) => (
                <tr key={item.name}>
                  {/*<div className="scItem">*/}
                  <td>
                    <Link to={item.path}>{item.name}</Link>
                  </td>
                  <td>{store.formatCurrency(item.unit_price)}</td>
                  <td><input ref={this.itemRefs[i]} style={{ width: '30px', textAlign: 'center' }} data-sku={item.sku} defaultValue={item.count}/></td>
                  <td style={{ textAlign: 'right', width: '80px' }}>{store.formatCurrency(item.unit_price * item.count)} &nbsp;</td>
                </tr>
              ))}
              <tr>
                <td colSpan="1"/>
                <td colSpan="3">
                  <hr/>
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="right">Cart Total:</td>
                <td>
                  <button onClick={() => {
                    transaction(() => {
                      this.itemRefs.forEach(ref => {
                        store.setCountForSku(ref.current.dataset.sku, ref.current.value)
                      })
                    })
                  }}>Update</button>
                </td>
                <td style={{ textAlign: 'right', width: '80px' }}>{store.formatCurrency(store.total)} &nbsp;</td>
              </tr>
              </tbody>
            </table>
            <Link to="" onClick={() => window.history.back()}><FaArrowLeft/> Go Back</Link> &nbsp;&nbsp;
            <Link style={{ float: "right" }} to="/checkout">Proceed to checkout <FaArrowRight/></Link>
          </div> : <div>
            <p>Your shopping cart is empty.</p>
            <Link to="" onClick={() => window.history.back()}><FaArrowLeft/> Go Back</Link> &nbsp;&nbsp;
            <Link style={{ float: "right" }} to="/store">Go to product list <FaArrowRight/></Link>
          </div>
        }
        {/*<br/><br/>*/}
        {/*<button onClick={() => store.doEmpty()}>Empty</button>*/}
      </Layout>
    )
  }
}

decorate(Cart, {
  render: observer
})

export default inject('store')(Cart)
